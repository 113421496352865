import { helpDeskStore } from '@/services/HelpDesk/store'
import { notifyError } from '@/services/notify'
import _get from 'lodash/get'

export const methods = {
  async reload() {
    console.debug('in reload...')

    await this.loadHelpDeskSubjects()
  },

  async loadHelpDeskSubjects() {
    if (this.isDebug == true) console.debug('in loadMessageTypes()...')
    await helpDeskStore.dispatch('getHelpDeskSupportList', {}).then(({ list }) => {
      this.helpDeskSubjects = list.map(a => ({
        value: a.helpDeskSubjectID,
        label: `${a.name}`
      }))
    })
  },

  async onFormSubmit() {
    if (!this.formData || this.formData == undefined) {
      notifyError('There was a problem submitting your message to customer support.')
      return
    }

    this.loading = true

    const payload = {
      helpDeskSubjectID: this.formData.helpDeskSubjectID,
      message: this.formData.message,
      hoaID: this.currentHoaId !== undefined && this.currentHoaId ? this.currentHoaId : null,
      unitID: this.currentUnitId !== undefined && this.currentUnitId ? this.currentUnitId : null,
      vendorUserID: _get(this.authUser, 'vendorUserID', null),
      aspNetUserID: 'stub'
    }

    console.debug('customer support payload=' + JSON.stringify(payload))

    try {
      await helpDeskStore.dispatch('addHelpDesk', {
        payload: payload,
        done: ({ result }) => {
          if (result) {
            this.setReadOnly = true
          }
        }
      })
    } catch (e) {
      notifyError('There was a problem with your custoer support submittal.' + e.message)
    }

    this.loading = false
  }
}
