/*
  import { helpDeskStore } from '@/services/HelpDesk/store'
*/

import Vue from 'vue'
import Vuex from 'vuex'
import { notifyError, notifyMessage } from '@/services/notify'
import kms from '@/services/kms'

const isDebug = true

/* eslint-disable no-empty-pattern */
const actions = {
  async getHelpDeskSupportList({}) {
    console.log('in getHelpDeskSupportList...')
    try {
      let path = `/HelpDesks/HelpDeskSubject/List`

      const results = await kms.get(path)

      if (isDebug == true) console.debug('getHelpDeskSupportList log=' + JSON.stringify(results))

      return {
        list: results.results
      }
    } catch (exception) {
      notifyError(`The help desk support list could not be retrieved.`)
      console.error(exception)
    }

    return {
      list: []
    }
  },

  async addHelpDesk({ dispatch }, { payload, done }) {
    if (isDebug == true) console.debug('...in addHelpDesk')
    try {
      const results = await kms.post(`/HelpDesks/HelpDesk`, {
        helpDeskSubjectID: payload.helpDeskSubjectID,
        message: payload.message,
        hoaID: payload.hoaID,
        unitID: payload.unitID,
        vendorUserID: payload.vendorUserID,
        aspNetUserID: payload.aspNetUserID
      })

      if (this.isDebug == true) console.debug('results=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      if (results.helpDeskID > 0) {
        notifyMessage(`Successfully submitted your customer support message.`)
        done({ result: results.helpDeskID })
      } else {
        notifyError('There was a problem adding this customer support message.')
      }
    } catch (exception) {
      notifyError(`There was a problem adding this customer support message.`)
      console.error(exception)
    }
  }
}
/* eslint-enable no-empty-pattern */

Vue.use(Vuex)
export const helpDeskStore = new Vuex.Store({
  state: {},
  mutations: {},
  getters: {},
  actions
})
