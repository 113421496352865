export const data = () => ({
  isDebug: true,
  loading: false,
  helpDeskSubjects: [],
  setReadOnly: false,
  formData: {
    helpDeskSubjectID: 0,
    message: null
  },

  styles: {
    dateSelector: {
      display: 'flex',
      padding: '12px',
      textAlign: 'center',
      verticalAlign: 'middle',
      flexWrap: 'wrap',
      alignItems: 'center',
      justifyContent: 'flex-end'
    },
    filterButton: {
      float: 'right',
      marginTop: '4px'
    }
  }
})
