<template>
  <div class="section" customer-support>
    <div class="container">
      <div class="level pl-2">
        <div class="level-left level-item title">{{ $t('helpDesk.title') }}</div>
      </div>
      <div class="box is-12">
        <ValidationObserver ref="form" v-slot="{ handleSubmit }">
          <transition name="fade" mode="out-in" appear>
            <form @submit.prevent="handleSubmit(onFormSubmit)" class="form">
              <fieldset>
                <div class="pb-5 pt-5">
                  <b-field label="Select Subject">
                    <b-select
                      :disabled="setReadOnly"
                      v-model="formData.helpDeskSubjectID"
                      required="true"
                      placeholder="Select a Subject"
                    >
                      <option
                        v-for="option in helpDeskSubjects"
                        :value="option.value"
                        :key="option.value"
                      >
                        {{ option.label }}
                      </option>
                    </b-select>
                  </b-field>
                </div>
                <div class="is-12 pt-5">
                  <valid-input
                    :disabled="setReadOnly"
                    style="width:50px !important;"
                    name="Message"
                    label="Message"
                    type="textarea"
                    vid="Message"
                    placeholder="Message"
                    spellcheck="true"
                    aria-label="Message"
                    rules="required|max:8000"
                    class="is-small"
                    v-model="formData.message"
                  />
                </div>
                <div class="pb-3 pt-6" v-if="setReadOnly === false">
                  <button type="submit" :disabled="loading" class="button is-primary is-rounded">
                    Submit
                  </button>
                </div>
                <div v-else class="pt-5 font-bold">
                  Support request has been successfully submitted.
                </div>
              </fieldset>
              <fieldset>
                <ValidationProvider vid="providerErrors" v-slot="{ errors }">
                  <div class="field">
                    <span>{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
              </fieldset>
            </form>
          </transition>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { mapGetters } from 'vuex'
import { data } from './keys/data'
import { methods } from './keys/methods'
import ValidInput from '@/components/inputs/ValidInput'

export default {
  name: 'EvotingDetails',
  components: {
    ValidInput,
    ValidationObserver,
    ValidationProvider
  },

  data,

  computed: {
    ...mapGetters('user', ['authUser', 'currentHoaId', 'currentUnitId'])
  },

  created() {},

  mounted() {
    this.reload()
  },

  methods,

  i18n: {
    messages: {
      en: {
        helpDesk: { title: 'Customer Support' }
      }
    }
  }
}
</script>
